body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: 'Darlington';
  src: local('Darlington'), url(/fonts/DarlingtonDemo.ttf) format('truetype');
}
.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: "white";
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.page {
  position: absolute;
  height: 100vh;
  width: 100%;
  top: 0;
  left: 0;
  /* margin: auto; */
  overflow: auto;
}

.icon {
    color: 'grey';
    padding: 10;
}

.icon::hover {
    color: 'black';
    padding: 10;
}

.d-flex div{
  padding: 1rem;
  text-align: center;
  color: black;
}

.fade-appear,
.fade-enter {
  opacity: 0;
  z-index: 1;
}
.fade-appear-active,
.fade-enter.fade-enter-active {
  opacity: 1;
  -webkit-transition: opacity 300ms linear 150ms;
  transition: opacity 300ms linear 150ms;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0;
  -webkit-transition: opacity 150ms linear;
  transition: opacity 150ms linear;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}



.accordion {
    width: 100%;
    height: 100%;
    /*overflow: hidden;*/
}

.accordion ul {
    width: 100%;
    height: 100%;
    display: table;
    table-layout: fixed;
    margin: 0;
    padding: 0;
}

.accordion ul li {
    display: table-cell;
    vertical-align: bottom;
    position: relative;
    width: 16.666%;
    height: 40vh;
    background-repeat: no-repeat;
    background-position: center center;
    -webkit-transition: all 500ms ease;
    transition: all 500ms ease;
    background-size: cover;
    box-shadow: inset 2px 2px 15px #000000;
}

.accordion ul li div {
    display: block;
    overflow: hidden;
    width: 100%;
    height: 100%;
}

.accordion ul li div a {
    display: block;
    height: 100%;
    width: 100%;
    position: relative;
    z-index: 3;
    vertical-align: bottom;
    padding: 15px 20px;
    box-sizing: border-box;
    color: #fff;
    text-decoration: none;
    font-family: Open Sans, sans-serif;
    -webkit-transition: all 500ms ease;
    transition: all 500ms ease;
}

.accordion ul li div a * {
    opacity: 0;
    margin: 0;
    position: absolute;
    z-index: 5;
    overflow: hidden;
    text-align: justify;
    -webkit-transform: translateX(75%);
    transform: translateX(200%);
    -webkit-transition: all 400ms ease;
    -webkit-transition: all 500ms ease 50ms;
    transition: all 500ms ease 50ms;
}
.accordion ul li div a h2 {
    font-family: Montserrat,sans-serif;
    font-size: 1.5vw;
    position: relative;
    text-transform: uppercase;
    margin-bottom: 2px;
    bottom: 10%;
}

.accordion ul li div a p {
    word-wrap: break-word;
    overflow: hidden;
    width: 90%;
    font-size: 0.75vw;
    bottom: 10%;
}

.accordion ul li div a h5 {
    font-family: "Times New Roman", Times, serif;
    font-style: italic;
    position: absolute;
    word-wrap: break-word;
    overflow: hidden;
    font-size: 18px;
    margin-right: 0;
    top: 2%;
    right: 2%;
    text-align: right;
}

.accordion ul li div a h4 {
    font-family: "Times New Roman", Times, serif;
    font-style: italic;
    position: absolute;
    word-wrap: break-word;
    overflow: hidden;
    font-size: 18px;
    margin-right: 0;
    top: 2%;
    left: 2%;
    text-align: left;
}

.accordion ul:hover li {width: 10%;}
.accordion ul:hover li:hover {width: 60%;}
.accordion ul:hover li:hover a {background: rgba(0, 0, 0, 0.7); }

.accordion ul:hover li:hover a * {
    opacity: 1;
    width: 90%;
    -webkit-transform: translateX(0);
    transform: translateX(0);
}


