.accordion {
    width: 100%;
    height: 100%;
    /*overflow: hidden;*/
}

.accordion ul {
    width: 100%;
    height: 100%;
    display: table;
    table-layout: fixed;
    margin: 0;
    padding: 0;
}

.accordion ul li {
    display: table-cell;
    vertical-align: bottom;
    position: relative;
    width: 16.666%;
    height: 40vh;
    background-repeat: no-repeat;
    background-position: center center;
    transition: all 500ms ease;
    background-size: cover;
    box-shadow: inset 2px 2px 15px #000000;
}

.accordion ul li div {
    display: block;
    overflow: hidden;
    width: 100%;
    height: 100%;
}

.accordion ul li div a {
    display: block;
    height: 100%;
    width: 100%;
    position: relative;
    z-index: 3;
    vertical-align: bottom;
    padding: 15px 20px;
    box-sizing: border-box;
    color: #fff;
    text-decoration: none;
    font-family: Open Sans, sans-serif;
    transition: all 500ms ease;
}

.accordion ul li div a * {
    opacity: 0;
    margin: 0;
    position: absolute;
    z-index: 5;
    overflow: hidden;
    text-align: justify;
    -webkit-transform: translateX(75%);
    transform: translateX(200%);
    -webkit-transition: all 400ms ease;
    transition: all 500ms ease 50ms;
}
.accordion ul li div a h2 {
    font-family: Montserrat,sans-serif;
    font-size: 1.5vw;
    position: relative;
    text-transform: uppercase;
    margin-bottom: 2px;
    bottom: 10%;
}

.accordion ul li div a p {
    word-wrap: break-word;
    overflow: hidden;
    width: 90%;
    font-size: 0.75vw;
    bottom: 10%;
}

.accordion ul li div a h5 {
    font-family: "Times New Roman", Times, serif;
    font-style: italic;
    position: absolute;
    word-wrap: break-word;
    overflow: hidden;
    font-size: 18px;
    margin-right: 0;
    top: 2%;
    right: 2%;
    text-align: right;
}

.accordion ul li div a h4 {
    font-family: "Times New Roman", Times, serif;
    font-style: italic;
    position: absolute;
    word-wrap: break-word;
    overflow: hidden;
    font-size: 18px;
    margin-right: 0;
    top: 2%;
    left: 2%;
    text-align: left;
}

.accordion ul:hover li {width: 10%;}
.accordion ul:hover li:hover {width: 60%;}
.accordion ul:hover li:hover a {background: rgba(0, 0, 0, 0.7); }

.accordion ul:hover li:hover a * {
    opacity: 1;
    width: 90%;
    -webkit-transform: translateX(0);
    transform: translateX(0);
}

